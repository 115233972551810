<template>
  <div class="content">
    <el-form style="width: 100%" :model="form" :rules="rules" ref="formName">
      <el-form-item label="来访地址：" class="form" prop="address">
        <el-input
          v-model="form.address"
          placeholder="请输入来访地址"
          class="formInput"
        ></el-input>
      </el-form-item>
      <el-form-item label="联系电话：" class="form" prop="tel">
        <el-input
          v-model="form.tel"
          placeholder="请输入联系电话"
          class="formInput"
        ></el-input>
      </el-form-item>
      <el-form-item label="服务邮箱：" class="form" prop="email">
        <el-input
          v-model="form.email"
          placeholder="请输入服务邮箱"
          class="formInput"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          style="margin-left: 10px;"
          type="primary"
          icon="el-icon-edit"
          @click="update('formName')"
          >保 存</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
// import { contactUsQueryObject } from '@'
import { contactUsQueryObject, contactUsUpdate } from '../../api/contactUs'
export default {
  name: 'index',
  data() {
    return {
      form: {
        address: null,
        email: null,
        tel: null,
        id: null
      },
      rules: {
        // 表单校验
        address: [{ required: true, message: '请输入地址', trigger: 'blur ' }],
        email: [{ required: true, message: '请输入邮箱', trigger: 'blur' }],
        tel: [{ required: true, message: '请输入联系电话', trigger: 'blur' }]
      }
    }
  },
  mounted() {
  this.contactUsQueryObject();
  },
  methods: {
    contactUsQueryObject(){
      contactUsQueryObject().then(res => {
        console.log('res', res)
        this.form = res
      })
    },
    //保存按钮
    update(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$confirm('您确定保存吗？', '信息提示', {
            cancelButtonClass: 'btn-custom-cancel',
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(() => {
              contactUsUpdate(this.form).then(res => {
                console.log(res)
                this.$alert('保存成功！', '信息提示', {
                  confirmButtonText: '确定',
                  type: 'success'
                }).then(() => {
                  // this.$refs[formName].resetFields()
                  this.contactUsQueryObject()
                })
              })
            })
            .catch(() => {})
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.content {
  border-radius: 4px;
  width: 95%;
  background-color: #fff;
  padding: 30px;
  //height: 38%;
  margin: 0 auto;
}
</style>
<style lang="less" scoped>
.home {
  margin: auto;
}
.form {
  width: 46%;
  //display: inline-block;
  margin: 25px 10px;
}
.form1 {
  width: 100%;
  display: inline-block;
  margin: 10px 10px;
}
.formInput {
  width: 67%;
}
/deep/.el-dialog__body {
  margin-top: -19px;
  margin-left: 15px;
}
/deep/.ql-editor {
  min-height: 100px !important;
}

/deep/.el-upload--picture-card {
  background-color: #fbfdff;
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  box-sizing: border-box;
  width: 148px;
  height: 148px;
  line-height: 80px;
  vertical-align: top;
}
/deep/.el-upload--picture-card {
  width: 70px;
  height: 70px;
  margin-top: 40px;
}

/deep/.ql-container {
  height: 50%;
}
/deep/.el-dialog__body {
  margin-left: 15px;
}
/deep/.el-form-item__error {
  margin-left: 95px;
}
.error/deep/.el-form-item__error {
  margin-top: 0;
}
/deep/ #quill-container {
  height: 100px;
}
/deep/ [data-v-0dd82d4c] .ql-editor {
  min-height: 100px !important;
}
/deep/.quillWrapper .ql-snow.ql-toolbar {
  padding-bottom: 0px;
}
/*去除upload组件过渡效果*/
/deep/ .el-upload-list__item {
  transition: none !important;
}
</style>
