import {get,post} from "../http/index";

// 详情
export const contactUsQueryObject = () => get('/contactUs/queryObject');

// 保存
export const contactUsUpdate = (params) => post('/contactUs/update', params);


//保存新增
export const rotateSave = (params) => post('/rotate/save', params)
